import './App.css';
import RoutesApp from './routes';

function App() {
  return (
    <div className="App">
      <RoutesApp></RoutesApp>
    </div>
  );
}

export default App;